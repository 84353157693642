body{
  background-color: #E9ECEF;
}

.custom-btn {
  background-color: #ED7E1F;
  border-color: #ED7E1F;
  color: white;
}

.custom-btn:hover {
  background-color: #0A3464;
  border-color: #0A3464;
}